// model
import {
  findSearchableProperty,
  recordType,
  searchableProperty
} from "@/model/record/recordModel";

// services
import {
  canSearchWithinRecord,
  createQueryModel
} from "@/services/query/queryService";

// mixins
import { dataTableSearchableDocumentsMixin } from "@/mixins/shared/documents/dataTableSearchableDocumentsMixin";
import {
  getFileContents,
  saveFile
} from "@/services/dom/fileSystemAccessService";

import { extensionName } from "@/model/record/fileModel";
import { countChildren } from "@/services/api/apiContent";

export const dataTableSearchableFolderDocumentsMixin = {
  mixins: [dataTableSearchableDocumentsMixin],
  computed: {
    /**
     * Data Table Title
     * Overwrites dataTableDocumentsMixin.dataTableTitle
     * @return {string} Formatted Data Table Title
     */
    dataTableTitle() {
      return this.hasFilter
        ? (this?.record?.id ?? 0) > 0
          ? `Search content of ${this?.record?.name}:`
          : "Search:"
        : `Content of ${this?.record?.name}`;
    },

    /**
     * can Search within a record
     * Overwrites searchableMixin.canSearchWithinRecord
     * @return {boolean}
     */
    canSearchWithinRecord() {
      return canSearchWithinRecord(this.record?.recordTypeId ?? -1);
    }
  },
  methods: {
    /**
     * Overwrite dataTableMixin.setItems()
     * @returns {Promise<void>}
     */
    async setItems() {
      try {
        this.clearError();

        this.isLoading = true;
        this.filterFieldsCount = 0;
        this.enabledPagination = false;

        this.clearDocuments();
        this.resetSearchableRecordProperties();

        // Set Documents
        try {
          // Load Searchable Record Property's fields
          try {
            let props = this.dataTableRecordPropsConfiguration();
            const propsCount = this.countSearchableFilterProps(props);

            // console.log(`setItems() config record props:`, props);
            console.log(
              `${this.$options.name}.setItems() propsCount:`,
              propsCount
            );

            if (propsCount === 0) {
              props = this.defaultSearchableRecordProps(this.defaultHeaders());

              // Insert childrenOnly by default
              const sp = findSearchableProperty(
                searchableProperty.childrenOnly
              );
              if (sp) {
                props.push({
                  name: sp.name,
                  include: false,
                  operator: sp.searchOperators[0].operator,
                  value: this.canSearchWithinRecord
                });
              }
            }

            if (this.record?.recordTypeId === recordType.FILE) {
              const spRecordId = findSearchableProperty(searchableProperty.id);

              if (spRecordId) {
                if (!props?.find(p => p.name === searchableProperty.id)) {
                  props.push({
                    name: spRecordId.name,
                    include: false,
                    operator: spRecordId.searchOperators[0].operator,
                    value: this.record?.id?.toString() ?? ""
                  });
                }
              }
            }

            console.log(`${this.$options.name}.setItems() props:`, props);

            this.loadSearchRecordProps(props);
          } catch (e) {
            console.error(e);
          }

          // Set Search Category
          await this.setSearchCategory(this.selectedRecordCategoryId);

          // Load Searchable Record's Fields
          try {
            const fields = this.dataTableFieldsConfiguration() || [];
            this.filterFieldsCount = this.countSearchableFilterFields(fields);
            // console.log(`filterFieldsCount:`, this.filterFieldsCount);
            // console.log(`filter fields:`, fields);
            this.loadSearchRecordFields(fields);
          } catch (e) {
            console.error(e);
          }

          // Insert Includable Fields to dataTable headers
          try {
            this.listHeaders = this.headers.filter(el => !!el.visible);
            this.insertIncludableRecordFields(this.listHeaders);
          } catch (e) {
            console.error(e);
          }
        } catch (e) {
          console.error(e);
        }

        const withInFolderId = this.canSearchWithinRecord
          ? this.record?.id ?? -1
          : -1;
        const payload = this.buildSearchPayload(withInFolderId, this.modelPage);

        if (this.enableUniversalSearch && this.hasUniversalSearchCriteria) {
          this.buildUniversalSearchPayload(payload);
        } else {
          this.fieldUniversalSearch = this.createUniversalSearchField();
        }

        console.log(`${this.$options.name}.setItems() payload:`, payload);

        this.isValidSearchCriteria = this.ensureValidFolderSearchCriteria(
          payload
        );

        console.log(
          `${this.$options.name}.setItems() this.isValidSearchCriteria:`,
          this.isValidSearchCriteria
        );

        console.log(
          `${this.$options.name}.setItems() this.totalCount:`,
          this.totalCount
        );

        //Add name to searchToolbar (outside Filter) if conditions meet
        //this.populateSearchField();

        // If Search Conditions are found, this will be true
        if (this.isValidSearchCriteria) {
          // Only Get Search Count first time, not on every page changed
          if (!this.totalCount) {
            const queryModel = createQueryModel(payload);
            this.totalCount = this.isValidSearchCriteria
              ? await this.getTotalResultCount(queryModel)
              : 0;
          }
          await this.setFoundDocuments(payload);
          // No search conditions provided, load only children
        } else {
          // Only Get Search Count first time, not on every page changed
          if (!this.totalCount) {
            if (withInFolderId >= 0)
              await countChildren(withInFolderId).then(response => {
                this.totalCount = response?.data;
              });
          }
          const payload = {
            id: withInFolderId,
            recordIndex: (this.modelPage - 1) * this.perPageCount + 1, //For every page No., multiply by countPerPage to prepare index for web api
            count: this.perPageCount
          };
          await this.setChildrenDocuments(payload);
        }
      } catch (e) {
        this.handleError(e.toString());
      } finally {
        this.isLoading = false;
        this.enabledPagination = true;
      }
    },
    /**
     * Overwrite Apply Search Filter
     * @param {Boolean} closeFilter
     * @return {Promise<void>}
     */
    async applySearchFilter(closeFilter = true) {
      try {
        this.filterError = "";
        closeFilter
          ? (this.isSearching = true)
          : (this.isApplyingSearch = true);
        this.isLoading = true;
        this.modelPage = 1; // set default page to 1 on every new search

        const withInId = this.canSearchWithinRecord
          ? this.record?.id ?? -1
          : -1;

        const payload = this.buildSearchPayload(withInId, this.modelPage);
        if (this.enableUniversalSearch && this.hasUniversalSearchCriteria) {
          this.buildUniversalSearchPayload(payload);
        } else {
          this.fieldUniversalSearch = this.createUniversalSearchField();
          //Add name to searchToolbar (outside Filter) if conditions meet
          //this.populateSearchField();
        }

        console.log(
          `${this.$options.name}.applySearchFilter() payload:`,
          payload
        );

        let props = this.searchableRecordProperties;
        // console.log(`${this.$options.name}.applySearchFilter() props:`, props);
        const propsCount = this.countSearchableFilterProps(props);
        // console.log(
        //   `${this.$options.name}.applySearchFilter() propsCount:`,
        //   propsCount
        // );
        if (propsCount === 0) {
          props = this.defaultSearchableRecordProps(this.defaultHeaders());
          this.loadSearchRecordProps(props);
        }

        const fields = this.searchableRecordFields;

        this.isValidSearchCriteria = this.ensureValidFolderSearchCriteria(
          payload
        );

        console.log(
          `${this.$options.name}.applySearchFilter() this.isValidSearchCriteria:`,
          this.isValidSearchCriteria
        );

        // If Search Conditions are provided, this will be true
        if (this.isValidSearchCriteria) {
          const queryModel = createQueryModel(payload);
          this.totalCount = this.isValidSearchCriteria
            ? await this.getTotalResultCount(queryModel)
            : 0;
          await this.setFoundDocuments(payload);
        } else {
          // No search conditions provided, load only children
          await countChildren(this.record?.id ?? -1).then(response => {
            this.totalCount = response?.data;
          });

          const payload = {
            id: this.record?.id ?? -1,
            recordIndex: (this.modelPage - 1) * this.perPageCount + 1, //For every page No., multiply by countPerPage to prepare index for web api
            count: this.perPageCount
          };
          await this.setChildrenDocuments(payload);
        }

        this.headers = this.defaultHeaders();
        this.setRecordPropertyHeadersVisibility(this.headers);

        this.listHeaders = this.headers.filter(el => !!el.visible);

        this.insertIncludableRecordFields(this.listHeaders);

        // Store Searchable Fields
        this.storeSearchableFields(fields);
        this.filterFieldsCount = this.countSearchableFilterFields(fields);

        // Store Searchable Record Props
        this.storeSearchableRecordProps(props);

        this.isLoading = true;
        this.menuFilterVisible = !closeFilter;
      } catch (e) {
        this.filterError = e.toString();
      } finally {
        this.isSearching = false;
        this.isApplyingSearch = false;
        this.isLoading = false;
      }
    },
    /**
     * ensure Valid Folder Search Criteria
     * @param payload
     * @return {boolean}
     */
    ensureValidFolderSearchCriteria(payload) {
      return this.hasSearchCriteria(payload);
      // const hasCriteria = this.hasSearchCriteria(payload);
      // if (hasCriteria) {
      //   return true;
      // }
      //
      // return false;

      // // Exception when home record (id===0) is selected
      // if (payload.WithInId === 0) {
      //   if (payload.ChildrenOnly.Value) {
      //     const includeFields =
      //       payload.Fields.filter(el => el.Include).length > 0;
      //     if (!includeFields) {
      //       return false;
      //     }
      //
      //     // 'a back door' to provide a valid, not empty, search criteria
      //     payload.Id.Value = 0;
      //     payload.Id.Operator = ">";
      //   }
      // } else {
      //   if (payload.ChildrenOnly.Value) {
      //     return false;
      //   }
      // }
      //
      // return true;
    },

    /**
     * Overwrite save User Search Template
     * @return {Promise<void>}
     */
    async saveUserSearchTemplate() {
      try {
        this.filterError = "";

        const name = "template";
        const extension = extensionName.text;

        const withInId = this.canSearchWithinRecord
          ? this.record?.id ?? -1
          : -1;
        const payload = this.buildSearchPayload(withInId);
        // Add GlobalSearch field to the payload object
        payload.GlobalSearch = this.fieldUniversalSearch;

        console.log(`saveUserSearchTemplate() payload:`, payload);

        const serializedObject = JSON.stringify(payload);
        const blob = new Blob([serializedObject], { type: "text/plain" });

        await saveFile(blob, name, extension);
      } catch (e) {
        this.filterError = e.toString();
      }
    },

    /**
     * overwrite get User Search Template()
     * @return {Promise<void>}
     */
    async getUserSearchTemplate() {
      try {
        this.filterError = "";
        const model = await getFileContents(".txt");
        this.populateUserSearchTemplateData(model);
      } catch (e) {
        this.filterError = e.toString();
      }
    },

    /**
     * Set Total Result count for provided Query
     * @param {{CategoryId: number, TypeId: number, Owner: string, CreatedDateTo: string, StateId: number, Creator: string, Name: string, Extension: string, Pages: number, Fields: {any}[], Version: number, CreatedDateFrom: string, ModifiedDateTo: string, ModifiedDateFrom: string}} queryModel
     * @return {Promise<void>}
     */
    async getTotalResultCount(queryModel) {
      try {
        const payload = {
          id: this.record?.id ?? -1,
          queryModel: queryModel
        };
        const result = await this.getTotalCountWithin(payload);
        return result?.data;
      } catch (e) {
        this.filterError = e.toString();
      }
    },

    /**
     * overwrite reset Search Filter
     * @param {Boolean} applySearch
     */
    async resetSearchFilter(applySearch = false) {
      try {
        this.filterError = "";
        this.performFilterReset();

        // Apply Search After Reset Done
        if (applySearch) await this.applySearchFilter();
      } catch (e) {
        this.filterError = e.toString();
      }
    }
  }
};
